<template>
  <div ref="container">
    <b-card-title class="card-title mt-1 mx-2">
      <b-row class="row">
        <b-col cols="4">
          <button class="btn btn-sm button-primary" @click="onAdd">
            <feather-icon
            size="15"
            icon="PlusSquareIcon"
            class="mr-50"
          />
          <span style="font-size: 15px">Crear Provincia</span>
          </button>
        </b-col>
        <b-col cols="8">
          <filter-bar-component v-on:search="onSearch"></filter-bar-component>
        </b-col>
      </b-row>
    </b-card-title>
    <b-card-body class="card-body py-2 px-0">
      <full-table-component
        ref="fulltable"
        :perPage="100"
        :fields="fields"
        url="/provinces"
      >
        <template slot="buttons-slot" slot-scope="props">
          <b-dropdown
            class="p-0"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                size="20"
                icon="SlidersIcon"
              />
            </template>
            <b-dropdown-item @click="onView(props.rowData)">
              <feather-icon
                size="20"
                icon="SearchIcon"
              />
              Ver detalles
            </b-dropdown-item>
            <b-dropdown-item @click="onEdit(props.rowData)">
              <feather-icon
                size="20"
                icon="Edit3Icon"
              />
              Editar
            </b-dropdown-item>
            <b-dropdown-item @click="onDelete(props.rowData)">
              <feather-icon
                size="20"
                icon="TrashIcon"
              />
              Eliminar
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </full-table-component>
    </b-card-body>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BDropdownItem,
  BDropdown,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
} from "bootstrap-vue";

import FilterBarComponent from "@/layouts/components/FilterBarComponent";
import FullTableComponent from "@/layouts/components/FullTableComponent";

import ProvinceService from "@/services/ProvinceService";
import AlertService from "@/services/AlertService";
import UtilService from "@/services/UtilService";
import UserService from "@/services/UserService";

import ProvinceEdit from "./ProvinceEdit";
import ProvinceView from "./ProvinceView";

export default {
  name: "ProvinceAll",
  components: {
    BDropdownItem,
    BDropdown,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    FilterBarComponent,
    FullTableComponent
  },
  data() {
    return {
      fields: [
        {
          name: "id",
          title: "ID",
          sortField: "id",
        },
        {
          name: "created_at",
          title: "CREADO",
          sortField: "created_at",
          formatter: (value) => {
            return UtilService.date(value);
          },
        },
        {
          name: "province",
          title: "PROVINCIA",
          sortField: "province",
        },
        {
          name: "keops_id",
          title: "KEOPS",
          sortField: "keops_id",
        },
        {
          name: "buttons-slot",
          title: "ACCIONES",
          titleClass: "text-right",
          dataClass: "text-right align-middle",
        },
      ],
    };
  },
  methods: {
    /**
     *
     */
    onSearch(event) {
      this.$refs.fulltable.search(event, {}, true);
    },

    /**
     *
     */
    onAdd() {
      this.$modal.show(
        ProvinceEdit,
        {
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },

    /**
     *
     */
    async onDelete(item) {
      const result = await AlertService.delete(
        "¿Estás seguro?",
        "Estas seguro de eliminar la provincia " + item.province
      );
      if (result.value) {
        this.isLoading = true;
        ProvinceService.destroy(item)
          .then(() => {
            this.isLoading = false;
            this.$refs.fulltable.refresh();
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Eliminar`,
                text: `Error al tratar de eliminar este elemento`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    /**
     *
     */
    async onEdit(item) {
      this.$modal.show(
        ProvinceEdit,
        {
          item: item,
          onClose: {
            handler: () => {},
          },
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },

    /**
     *
     *
     */
    async onView(item) {
      this.$modal.show(
        ProvinceView,
        {
          item: item,
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },
  },
  mounted() {
    if (!UserService.isAdminOrStaff()) {
      this.$router.push("/");
    }
  },
};
</script>
