<template>
  <div class="m-3">
    <div>
      <h3 class="title">DETALLE DE PROVINCIA</h3>
      <div class="row justify-content-center" v-if="province">
        <div class="col-sm-12 col-md-12">
          <div class="card-body p-3">
            <RowComponent :title="'ID:'" :content="province.id.toString()" />
            <RowComponent :title="'PROVINCIA:'" :content="province.province" />
            <div>
              <p>
                <b>LOCALIDADES :</b>
              </p>
              <b-list-group style="max-height: 200px; overflow: auto">
                <b-list-group-item
                  v-bind:key="item.id"
                  v-for="item in province.locations"
                  >{{ item.location }}</b-list-group-item
                >
              </b-list-group>
            </div>
            <br />
            <div>
              <p>
                <b>CREADO :</b>
                <span>{{ province.created_at | date_format }}</span>
              </p>
            </div>
            <div>
              <p>
                <b>ACTUALIZADO :</b>
                <span>{{ province.updated_at | date_format }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer>
      <div class="row">
        <div class="col-12">
          <b-button
            class="mr-1 float-right"
            variant="primary"
            @click="onClose"
          >
            Cerrar
          </b-button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import {
  BButton,
  BListGroup,
  BListGroupItem
} from "bootstrap-vue";

import ProvinceService from "@/services/ProvinceService";
import UserService from "@/services/UserService";

export default {
  name: "ProvinceView",
  components:{
    BButton,
    BListGroup,
    BListGroupItem
  },
  props: {
    item: Object,
  },
  data() {
    return {
      isAdmin: UserService.isAdmin(),
      isAdminOrStaff: UserService.isAdminOrStaff(),
      province: null,
    };
  },
  methods: {
    /**
     *
     */
    onClose() {
      this.$emit("close");
    },

    /**
     *
     */
    show() {
      this.isLoading = true;
      ProvinceService.show(this.item.id)
        .then((response) => {
          const { data } = response;
          this.province = data.data;
          this.isLoading = false;
        })
        .catch((thrown) => {
          this.isLoading = false;
          console.error(thrown);
        });
    },
  },
  mounted() {
    this.show();
  },
};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 500px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
