<template>
  <div class="m-3">
    <div class="card-body card-modal pt-0">
      <h3 class="title">Editar localidad</h3>
      <div class="row justify-content-center" v-if="location">
        <div class="col-12">
          <validation-observer
            ref="editLocationForm"
          >
          <b-form @submit="onSubmit">
             <!-- Provincia -->
            <b-form-group label="Provincia" description="Selecciona una provincia.">
              <validation-provider
                #default="{ errors }"
                name="Provincia"
                rules="required"
              >
                <b-form-select
                  size="sm"
                  name="province"
                  v-model="location.province_id"
                  :options="provinces"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Localidad -->
            <b-form-group label="Localidad">
              <validation-provider
                #default="{ errors }"
                name="Localidad"
                rules="required|max:150"
              >
                <b-form-input
                  size="sm"
                  v-model="location.location"
                  autocomplete="off"
                  placeholder="Localidad"
                  type="text"
                  name="location"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
    <footer class>
      <div class="row">
        <div class="col-12">
          <b-button
            size="sm"
            class="mr-1 float-right"
            variant="primary"
            @click="onSubmit"
          >
            Guardar
          </b-button>
          <b-button size="sm" class="mr-1 float-right" variant="outline-primary" @click="close">
            Cancelar
          </b-button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormSelect,
  BFormInput
} from "bootstrap-vue";
import LocationService from "@/services/LocationService";
import { required } from '@validations'

export default {
  name: "LocationEdit",
  components:{
    BButton,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormInput,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    onSave: Object,
    item: Object,
  },
  data() {
    return {
      required,
      provinces: [],
      location: null,
    };
  },
  methods: {
    /**
     *
     */
    onSubmit(event) {
      event.preventDefault();
      this.$refs.editLocationForm.validate().then((valid) => {
        if (valid) {
          LocationService.save(this.location, this.location.id)
            .then(({ data }) => {
              this.$emit("close");
              this.onSave.handler(data);
            })
            .catch((error) => {
              console.error(error);
              const response = error.response;
              if (response && response.status === 422) {
                const keys = Object.keys(response.data.errors);
                keys.forEach((key) => {
                  response.data.errors[key].forEach((error) => {
                    this.veeErrors.add({
                      field: key,
                      msg: error,
                    });
                  });
                });
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Actualizar`,
                    text: `Error al tratar de almacenar este elemento`,
                    icon: "AlertOctagonIcon",
                    variant: "danger",
                  },
                });
              }
            });
        }
      });
    },

    /**
     *
     */
    close() {
      this.$emit("close");
    },

    /**
     *
     */
    loadProvinces() {
      LocationService.provinces().then(({ data }) => {
        this.provinces = data.data;
      });
    },

    /**
     *
     */
    show() {
      this.isLoading = true;
      LocationService.show(this.item.id)
        .then((response) => {
          const { data } = response;
          this.location = data.data;
          this.isLoading = false;
        })
        .catch((thrown) => {
          this.isLoading = false;
          console.error(thrown);
        });
    },
  },
  mounted() {
    this.loadProvinces();
    if (this.item) {
      this.show();
    } else {
      this.location = { id: null };
    }
  },
};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 250px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
