<template>
  <b-card>
    <b-tabs>
      <b-tab title="LOCALIDADES" active>
        <location-all />
      </b-tab>
      <b-tab title="PROVINCIAS">
        <province-all />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue'

import ProvinceAll from "./province/ProvinceAll";
import LocationAll from "./location/LocationAll";

export default {
  name: "LocationProvinceAll",
  components: {
    BCard, 
    BTabs, 
    BTab,
    ProvinceAll,
    LocationAll,
  },
  mounted() {},
  data() {
    return {};
  },
};
</script>
