<template>
  <div class="m-3">
    <div>
      <h3 class="title">DETALLE DE LOCALIDAD</h3>
      <div class="row justify-content-center" v-if="location">
        <div class="col-sm-12 col-md-12">
          <div class="card-body p-3">
            <RowComponent :title="'ID:'" :content="location.id" />
            <RowComponent :title="'PROVINCIA:'" :content="location.province.province" />
            <RowComponent :title="'LOCALIDAD:'" :content="location.location" />

            <div v-if="location.services.length > 0">
              <p>
                <b>SERVICIOS :</b>
              </p>
              <b-list-group style="max-height: 200px; overflow: auto">
                <b-list-group-item v-bind:key="item.id" v-for="item in location.services">
                  <p class="m-1" v-if="item.type === 'collect'">
                    Cobro por colecta : {{ item.amount }} $
                  </p>
                </b-list-group-item>
              </b-list-group>
              <br />
            </div>

            <div v-if="location.points.length > 0">
              <p>
                <b>PUNTOS :</b>
              </p>
              <b-list-group style="max-height: 200px; overflow: auto">
                <b-list-group-item v-bind:key="item.id" v-for="item in location.points">
                  <p class="m-1">
                    {{ item.street }}
                    {{ item.height }}
                    {{ item.postal_code }}
                    {{ item.street }}
                    <br />
                    <small>Nota {{ item.note }}</small>
                  </p>
                </b-list-group-item>
              </b-list-group>
              <br />
            </div>

            <div>
              <p>
                <b>CREADO :</b>
                <span>{{ location.created_at | date_format }}</span>
              </p>
            </div>
            <div>
              <p>
                <b>ACTUALIZADO :</b>
                <span>{{ location.updated_at | date_format }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer>
      <div class="row">
        <div class="col-12">
          <b-button class="mr-1 float-right" variant="primary" @click="onClose">
            Cerrar
          </b-button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import {
  BButton
} from "bootstrap-vue";
import LocationService from "@/services/LocationService";
import UserService from "@/services/UserService";

export default {
  name: "LocationView",
  components:{
    BButton,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      isAdmin: UserService.isAdmin(),
      isAdminOrStaff: UserService.isAdminOrStaff(),
      location: null,
    };
  },
  methods: {
    /**
     *
     */
    onClose() {
      this.$emit("close");
    },

    /**
     *
     */
    show() {
      this.isLoading = true;
      LocationService.show(this.item.id)
        .then((response) => {
          const { data } = response;
          this.location = data.data;
          this.isLoading = false;
        })
        .catch((thrown) => {
          this.isLoading = false;
          console.error(thrown);
        });
    },
  },
  mounted() {
    this.show();
  },
};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 500px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
