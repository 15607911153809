import axios from 'axios';

export default class ProvinceService {

    /**
     * @description login 
     * @param { username | login, password } values 
     */
    static index() {
        return axios.post(process.env.VUE_APP_API + '/provinces');
    }

    /**
     * 
     */
    static save(data) {
        if (data.id) {
            return this.update(data, data.id)
        }
        return this.store(data);
    }

    /**
     * @description store data  
     * @param data { location_id, street, height,  postal_code } values 
     */
    static store(data) {
        return axios.post(process.env.VUE_APP_API + '/provinces', data);
    }

    /**
     * @description get a element by id  
     * @param { id } id point 
     */
    static update(data, id) {
        return axios.put(process.env.VUE_APP_API + '/provinces/' + id, data);
    }

    /**
     * @description delete a element 
     * @param { id } id point 
     */
    static destroy({ id }) {
        return axios.delete(process.env.VUE_APP_API + '/provinces/' + id);
    }

    /**
     * @description get a element by id  
     * @param { id } id point 
     */
    static show(id) {
        return axios.get(process.env.VUE_APP_API + '/provinces/' + id);
    }

}