<template>
  <div class="m-3 ">
    <h3 class="title">Editar Provincia</h3>
    <div class="row justify-content-center card-modal">
      <div class="col-12" v-if="province">
        <validation-observer
          ref="editProvinceForm"
        >
        <b-form @submit="onSubmit">

          <!-- Provincia -->
          <b-form-group label="Provincia">
            <validation-provider
              #default="{ errors }"
              name="Provincia"
              rules="required|max:255"
            >
              <b-form-input
                size="sm"
                v-model="province.province"
                autocomplete="off"
                placeholder="Provincia"
                type="text"
                name="province"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          
          <!-- Provincia -->
          <b-form-group label="Keops Id">
            <validation-provider
              #default="{ errors }"
              name="Provincia"
              rules="required|max:255"
            >
              <b-form-input
                size="sm"
                v-model="province.keops_id"
                autocomplete="off"
                placeholder="Provincia"
                type="text"
                name="keops_id"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
        </validation-observer>
      </div>
    </div>
    <footer class>
      <div class="row">
        <div class="col-12">
          <b-button
            size="sm"
            class="mr-1 float-right"
            variant="primary"
            @click="onSubmit"
          >
            Guardar
          </b-button>
          <b-button size="sm" class="mr-1 float-right" variant="outline-primary" @click="close">
            Cancelar
          </b-button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput
} from "bootstrap-vue";
import ProvinceService from "@/services/ProvinceService";

export default {
  name: "ProvinceEdit",
  components:{
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    onSave: Object,
    item: Object,
  },
  data() {
    return {
      province: null,
    };
  },
  methods: {
    /**
     *
     */
    show() {
      this.isLoading = true;
      ProvinceService.show(this.item.id)
        .then((response) => {
          const { data } = response;
          this.province = data.data;
          this.isLoading = false;
        })
        .catch((thrown) => {
          this.isLoading = false;
          console.error(thrown);
        });
    },

    /**
     *
     */
    onSubmit(event) {
      event.preventDefault();
      this.$validator.validate().then((valid) => {
        if (valid) {
          ProvinceService.save(this.province, this.province.id)
            .then(({ data }) => {
              this.$emit("close");
              this.onSave.handler(data);
            })
            .catch((error) => {
              console.error(error);
              const response = error.response;
              if (response && response.status === 422) {
                const keys = Object.keys(response.data.errors);
                keys.forEach((key) => {
                  response.data.errors[key].forEach((error) => {
                    this.veeErrors.add({
                      field: key,
                      msg: error,
                    });
                  });
                });
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Actualizar`,
                    text: `Error al tratar de almacenar este elemento`,
                    icon: "AlertOctagonIcon",
                    variant: "danger",
                  },
                });
              }
            });
        }
      });
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    if (this.item) {
      this.show();
    } else {
      this.province = { id: null };
    }
  },
};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 200px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
